.active i{color: #007bff;}

.lin3 span{
	/* background: url(../images/text.svg) no-repeat left 10px; */
	height: 31px;
	width: 20px;
	display: inline-block;
}
.active-text span{
	/* background: url("../images/text-activer.svg") no-repeat left 10px; */
	height: 31px;
	width: 20px;
	display: inline-block;
}

.Toastify__toast--error {
  background-color: #f15a24;
}

.Toastify__toast--info {
  background-color: #08222d;
}

.validate-input {
  font-size: 13px;
  padding-left: 5px;
  color: red;
}

.coloroption {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin: 5px;
}

.highlightercoloroption {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin: 5px;
}

.padding-80 {
  padding-top: 80px;
}

.bg-gray {
  background-color: #4d4d4d;
}

.fadeOut {
  opacity: 0;
  transition: opacity 1s;
}

.fadeIn {
  opacity: 1;
  transition: opacity 1s;
}

.h-30 {
  transition: height 0.5s;
}

.h-0 {
  height: 0px;
  transition: height 0.5s;
}

.imageContainer {
  width: 96%;
  height: 800px;
  max-width: 800px;
  max-height: 600px;
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.imageContainer>img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  cursor: move;
  touch-action: none;
}

.left-tool-option {
  /* display: none; */
  position: absolute;
  left: 48px;
}

.right-tool-option {
  /* display: none; */
  position: absolute;
  right: 60px;
}

.right-col1 {
  z-index: 10;
}

.right-col2 {
  z-index: 10;
}

.active1 {
  /* background: #f15a24; */
  background: #007bff;
}

.modal-content {
  background: inherit;
  border-width: 0;
  pointer-events: none;
}

.pte-auto{
  pointer-events: auto;
}

.bg-epen {
  background: #08222d;
}

.close1a{
  height: 32px;
  width: 32px;
  right: 40px;
  position: absolute;
  top: -20px;
  
}
.chup_mh{
  position: absolute;
  right: 80px;
  top: -20px;
  border: none;
  background: rgba(255, 255, 255, 0.5) !important;
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

.rotate_x_left_after{
  transform: rotateY(90deg);
  opacity: 0;
  transition: transform 1s, opacity 1s;
  transform-origin: left;
}

.rotate_x_left_before{
  opacity: 1;
  transition: transform 1s, opacity 1s;
  transform-origin: left;
}