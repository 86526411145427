/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
.header-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.box-sp {
  background: rgba(0, 0, 0, 0.4);
  bottom: 0px;
  left: 0;
  right: 0;
  line-height: 1.2;
}
.bt-nagative-1 {
  bottom: -0.5px !important;
}
.three-dot-120 {
  width: 120%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.three-dot-100 {
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.modal-desktop {
  width: 52%;
  margin: 0 auto;
}
.pointer{
  cursor: pointer;
}
.list-message-browser-height{
  height: calc(100vh - 35rem) !important;
}
.bg-black-alpha-05{
  position: fixed;
  background-color: rgba(0,0,0,0.5);
  width: 100%;
  height: 100vh;
  top: 0px;
  left: 0px
}