.wrapper {
    max-width: 100%;
    padding: 0;
}

.w-50 {
    max-width: 50%;
}

.button-join,
.button-view {
    background: inherit;
    border: none;
    color: #fff;
    cursor: pointer;
}

.button-join:focus,
.button-view:focus {
    outline: none;
}
.form-style {
    background: rgba(0, 0, 0, 0.5) !important;
    border: none;
    color: rgba(255, 255, 255, 1);
    border-radius: 0.5rem;
    display: block;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #000;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.bt-table{
    bottom: 22px !important;
}
.inner-scroll-ipad{
    height: calc(100vh - 45rem) !important;
    overflow-y:auto;
    margin:0px;
}
.hidden-scrollbar-scoll .inner-scoll-edit-class-desktop {
    height: calc(100vh - 29rem) !important;
    overflow-y: auto;
    margin-top: 86px;
    margin-left: 0px;
}
.posbox1 {
    height: calc(100vh - 19rem);
}
.bt-tbl-techer-cls{
    bottom: 9px !important;
}
.posbox2-ipad-landscape{
    bottom: 10px;
    right: 40px;
    left: 40px;
    top: 325px;
}
.inner-scroll-ipad-landscape {
    height: calc(100vh - 32rem) !important;
    overflow-y: auto;
    margin: 0px;
}
@media screen and (max-width: 576px) {
    .inner-scroll-ipad{
        height: calc(100vh - 28rem) !important;
    }
}